import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import {
    translate,
    DashboardMenuItem,
    MenuItemLink,
    WithPermissions
} from 'react-admin';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { 
  Map as LiveIcon,
  Timelapse as HistoryIcon,
  Search as SearcObsIcon
} from '@material-ui/icons'

import { CampaignIcon } from './resources/campaigns';
import { DatasetIcon } from './resources/datasets';
import { EquipmentIcon } from './resources/equipments';
import { InstitutionIcon } from './resources/institutions';
import { TeamIcon } from './resources/teams';
import { UserIcon } from './resources/users';
import { OverlayIcon } from './resources/overlays';
import { FilesIcon } from './resources/files';
import { DerivedDatasetIcon } from './resources/derivedDatasets';
import { EquipmentTypeIcon } from './resources/equipment-types';
import { EquipmentModelIcon } from './resources/equipment-models';
import { ObservedPropertiesIcon } from './resources/observedProperties';
import { TagIcon } from './resources/tags';
import { ParametersIcon } from './resources/parameters';

import SubMenu from './SubMenu';


const styles = theme => ({
  active: {
    color: theme.palette.text.primary,
  }
});

const MenuEntry = ({classes, handleToggle, isOpen, item, location, onMenuClick, open, permissions, translate}) => {
  return item.submenu && permissions && item.submenuAccess.includes(permissions) ? <SubMenu
      handleToggle={() => handleToggle(`menu_${item.name}`)}
      isOpen={isOpen}
      sidebarIsOpen={open}
      name={`pos.submenu.${item.name}`}
      icon={item.icon}
    >
      {
        item.submenu.map( submenu => {
          return <MenuItemLink
          key={submenu.name}
            to={`/${item.subType === 'maps' ? 'maps/'+submenu.name: submenu.name}`}
            primaryText={translate(`${submenu.type}.${submenu.subType ? submenu.subType + '.' : ''}${submenu.name}.name`, {
              smart_count: 2,
            })}
            leftIcon={submenu.icon}
            onClick={onMenuClick}
            className={Array.isArray(submenu.routes) && submenu.routes.includes(location.pathname.split('/')[1]) ? classes.active: ''}
          />
        })
      }
    </SubMenu>
  : 
    <MenuItemLink
      to={`/${item.subType === 'maps' ? 'maps/'+item.name: item.name}`}
      primaryText={translate(`${item.type}.${item.subType ? item.subType+'.' : ''}${item.name}.name`, {
        smart_count: 2,
      })}
      leftIcon={item.icon}
      onClick={onMenuClick}
      className={Array.isArray(item.routes) && item.routes.includes(location.pathname.split('/')[1]) ? classes.active: ''}
    />
}


class Menu extends Component {

  constructor(props){
    super(props);
    this.items = [
      { name: 'live', icon: <LiveIcon />, type: 'containers', subType:'maps' }, 
      { name: 'history', icon: <HistoryIcon />, type: 'containers', subType:'maps' },
      { name: 'institutions', icon: <InstitutionIcon />, type: 'resources', access: ['sysadmin'] },
      { name: 'users', icon: <UserIcon />, type: 'resources', access: ['sysadmin', 'admin'] },
      { name: 'campaigns', icon: <CampaignIcon />, type: 'resources', routes:['launches'] },
      { name: 'teams', icon: <TeamIcon />, type: 'resources' },
      { name: 'equipment', icon: <EquipmentIcon />, type: 'resources', access: ['sysadmin', 'admin', 'user'], submenuAccess: ['sysadmin'],
          submenu: [
            {name: 'equipment', icon: <EquipmentIcon />, type: 'resources'},
            {name: 'equipmentmodels', icon: <EquipmentModelIcon />, type: 'resources'},
            {name: 'equipmenttypes', icon: <EquipmentTypeIcon />, type: 'resources'},
            {name: 'observedProperties', icon: <ObservedPropertiesIcon/> , type: 'resources'}
          ]
      },
      { name: 'datasets', icon: <DatasetIcon />, type: 'resources', access: ['sysadmin', 'admin', 'user'], submenuAccess: ['sysadmin', 'admin', 'user'],
          submenu: [
            { name: 'datasets', icon: <DatasetIcon />, type: 'resources', routes: ['observations'] },
            { name: 'derivedsets', icon: <DerivedDatasetIcon />, type: 'resources'},
            { name: 'parameters', icon: <ParametersIcon/> , type: 'resources' },
            { name: 'search', icon: <SearcObsIcon/> , type: 'containers' },
            { name: 'tags', icon: <TagIcon />, type: 'resources', routes: ['observations'] },
          ] 
      },
      { name: 'overlays', icon: <OverlayIcon />, type: 'resources' },
      { name: 'files', icon: <FilesIcon />, type: 'resources' },
    ];

    this.state = {
      menu_datasets: false,
      menu_equipment: false
    }

  }

  handleToggle = menu => {
    this.setState(state => ({ [menu]: !state[menu] }));
  };

  render(){
    return <div>
      <DashboardMenuItem onClick={this.props.onMenuClick} />
      {this.items.map(item => {
        if(!item.access || item.access.length === 0){
          return <MenuEntry key={item.name} item={item} handleToggle={this.handleToggle} isOpen={this.state[`menu_${item.name}`]} {...this.props} />
        }
        return <WithPermissions key={item.name} render={({permissions}) => {
          if(permissions && item.access.includes(permissions)){
            return <MenuEntry key={item.name} item={item} handleToggle={this.handleToggle} isOpen={this.state[`menu_${item.name}`]} permissions={permissions} {...this.props} />
          }else{
            return null;
          }
        }} />
      })}
    </div>
  }

}

const enhance = compose(
  withStyles(styles),
  withRouter,
  translate,
  connect(
    state => ({
      theme: state.theme,
      locale: state.i18n.locale,
      open: state.admin.ui.sidebarOpen
    })
  ),
);

export default enhance(Menu);
